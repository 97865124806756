import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section5.module.scss'
import { logo, homeSection5, smallBuildings } from '@images'
import { Fade, Reveal } from 'react-reveal'

const Section5 = props => {
  const { className, children, title, buttons } = props

  return (<div id="section-5" className={[className, styles.container].join(' ')}>
    <div className={['row', styles.centered].join(' ')}>
      <div className="col-sm-6 elt-to-animate offset-sm-1">
        <Fade big top>
          <img className="d-none d-sm-inline mb-3" src={logo} alt="tsplus logo" width="232"/>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>
            {children}
          </div>
          {buttons}
        </Fade>
      </div>
      <div className="col-sm-4 d-none d-md-block">
        <Fade bottom>
          <img className="img-tsplus-app " src={homeSection5} alt="tsplus application screenshot"/>
        </Fade>
      </div>
    </div>
    <div className={styles.shapes}>
      <div className={styles.image}>
        <Reveal>
          <img src={smallBuildings} alt="small buildings background"/>
        </Reveal>
      </div>
    </div>
  </div>)
}

Section5.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any
}

export default Section5
