import React from 'react'
import PropTypes from 'prop-types'

import styles from './Section1.module.scss'

import { logo, numericCity } from '@images'
import { Fade, Reveal } from 'react-reveal'

const Section1 = props => {
  const { className, children, title, buttons } = props


  return (<div id="section-1" className={[className, styles.container].join(' ')}>
    <div className={[styles.eltToAnimate].join(' ')}>
      <div className={['row', styles.centered].join(' ')}>
        <div className="col-md-12 text-center">
          <Reveal>
            <img className={[styles.logo].join(' ')} src={logo} alt="logo"/>
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>
              {children}
            </div>
            {buttons}
          </Reveal>
        </div>
      </div>
    </div>
    <div>
      <Fade left>
        <div className={styles.bottomLeft}>
          <img src={numericCity} alt="small buildings background"/>
        </div>
      </Fade>
      <Fade right>
        <div className={styles.bottomRight}>
          <img src={numericCity} alt="small buildings background"/>
        </div>
      </Fade>
    </div>
  </div>)
}

Section1.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  language: PropTypes.string
}

export default Section1
