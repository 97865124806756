import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section4.module.scss'
import { homeSection4, logo } from '@images'
import { Fade } from 'react-reveal'
import { useIntl } from 'gatsby-plugin-intl'

const Section4 = props => {
  const { className, children, title, buttons } = props
  const { formatMessage } = useIntl()

  return (<div id="section-4" className={[className, styles.container].join(' ')}>
    <div className={['row', styles.centered].join(' ')}>
      <div className="col-sm-5 text-center">
        <Fade bottom>
          <img className={styles.imageSection} src={homeSection4} alt="tsplus application screenshot"/>
        </Fade>
      </div>
      <div className="col-sm-7 ">
        <Fade big top>
          <img className={['d-none', 'd-md-block', 'mb-3', styles.logo].join(' ')} src={logo} alt="tsplus logo"/>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>
            {children}
          </div>
          {buttons}
        </Fade>
      </div>
    </div>
  </div>)
}

Section4.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any
}

export default Section4
