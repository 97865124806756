import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@layouts/Layout'
import { useIntl } from 'gatsby-plugin-intl'
import { Section1, Section2, Section3, Section4, Section5, Section6 } from '@templates/index'
import Button from '@components/buttons/Button'
import GroupButtons from '@components/group_buttons/GroupButtons'

const Home = props => {
  const { pageContext } = props
  const { languageName, language } = pageContext
  const { formatMessage } = useIntl()
  const Buttons = () => {
    return (<GroupButtons>
      <Button href={`/${language}/features`}>{formatMessage({ id: 'learn-more' })}</Button>
      <Button href={`/${language}/download`}>{formatMessage({ id: 'download-trial' })}</Button>
    </GroupButtons>)
  }

  const Content = options => {
    const { id } = options
    return <div dangerouslySetInnerHTML={{ __html: formatMessage({ id }) }} />
  }

  return <Layout languageName={languageName} pageContext={pageContext}>
    <div className='row'>
      <Section1 className='col-md-12' title={formatMessage({ id: 'index-section-1-title' })} language={language} buttons={<Buttons/>}>
        <Content id='index-section-1-content'/>
      </Section1>
      <Section2 className='col-md-12' title={formatMessage({ id: 'index-section-2-title' })} language={language} buttons={<Buttons/>}>
        <Content id='index-section-2-content'/>
      </Section2>
      <Section3 className='col-md-12' title={formatMessage({ id: 'index-section-3-title' })} language={language} buttons={<Buttons/>}>
        <Content id='index-section-3-content'/>
      </Section3>
      <Section4 className='col-md-12' title={formatMessage({ id: 'index-section-4-title' })} language={language} buttons={<Buttons/>}>
        <Content id='index-section-4-content'/>
      </Section4>
      <Section5 className='col-md-12' title={formatMessage({ id: 'index-section-5-title' })} language={language} buttons={<Buttons/>}>
        <Content id='index-section-5-content'/>
      </Section5>
      <Section6 className='col-md-12' title={formatMessage({ id: 'index-section-6-title' })} language={language} buttons={<Buttons/>}>
        <Content id='index-section-6-content'/>
      </Section6>
    </div>
  </Layout>
}

Home.propTypes = {
  pageContext: PropTypes.any
}

export default Home
