import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section6.module.scss'
import { logo, homeSection6 } from '@images'
import { Fade } from 'react-reveal'

const Section6 = props => {
  const { className, children, title, buttons } = props

  return (<div id="section-6" className={[className, styles.container].join(' ')}>
    <div className={['row', styles.centered].join(' ')}>
      <div className={['col-md-3', 'col-sm-12', 'offset-sm-1', 'text-center'].join(' ')}>
        <Fade bottom>
          <img id="img-safe-guy" className={['img-fluid', styles.sectionImage].join(' ')} src={homeSection6} alt="un garçon avec coffret"/>
        </Fade>
      </div>
      <div className="col-md-7 col-sm-12 offset-sm-1">
        <Fade big top>
          <img className={['mb-3', 'd-none d-md-block', styles.logo].join(' ')} src={logo} alt="tsplus logo"/>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>
            {children}
          </div>
          {buttons}
        </Fade>
      </div>
    </div>
  </div>)
}

Section6.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any
}

export default Section6
