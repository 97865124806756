import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section3.module.scss'
import { logo, homeSection3, smallBuildings } from '@images'
import { Fade, Reveal } from 'react-reveal'

const Section3 = props => {
  const { className, children, title, buttons } = props

  return (<div id="section-3" className={[className, styles.container].join(' ')}>
    <div className={['row', styles.centered].join(' ')}>
      <div className="col-sm-7">
        <Fade top big>
          <img className="d-none d-sm-inline mb-3" src={logo} alt="tsplus logo" width="232"/>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>
            {children}
          </div>
          {buttons}
        </Fade>
      </div>
      <div className="col-md-4 col-ms-12 text-center d-none d-md-block">
        <Fade bottom>
          <img src={homeSection3} alt="tsplus application screenshot"/>
        </Fade>
      </div>
    </div>

    <div className={styles.shapes}>
      <div className={styles.image}>
        <Reveal>
          <img src={smallBuildings} alt="small buildings background"/>
        </Reveal>
      </div>
    </div>
  </div>)
}

Section3.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any
}

export default Section3
