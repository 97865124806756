import React from "react"
import PropTypes from "prop-types"
import styles from "./Section2.module.scss"
import { logo, tabletYoutube, youtubePlay } from "@images"
import { Fade, Roll } from "react-reveal"
import ReactPlayer from "react-player/youtube"
import videoEs from "./videos/TSPlus_intro_1.mp4"
import videoEn from "./videos/TSPlus_Intro_English.mp4"
import videoPr from "./videos/TSPlus intro_Portugues_1.mp4"
import { Player, BigPlayButton } from "video-react"
import "video-react/dist/video-react.css"

const Section2 = props => {
  const { className, children, title, buttons, language } = props
  let videos = {
    es: videoEs,
    en: videoEn,
    pr: videoPr,
  }
  var VideoShow = ""
  if (language === "es") {
    VideoShow = videos.es
  } else if (language === "en") {
    VideoShow = videos.en
  } else {
    VideoShow = videos.pr
  }

  return (
    <div id="section-2" className={[className, styles.container].join(" ")}>
      <div className={["row", styles.centered].join(" ")}>
        <div className="col-sm-7 text-center">
          <div
            className={[styles.eltToAnimate, "ml-auto"].join(" ")}
            id="tablet-youtube-video"
          >
            <Roll left>
              <Player src={VideoShow}>
                <BigPlayButton position="center" />
              </Player>
            </Roll>
          </div>
        </div>
        <div className={["col-sm-5", styles.eltToAnimate].join(" ")}>
          <Fade right>
            <img
              className={["d-none", "d-sm-inline", styles.logo].join(" ")}
              src={logo}
              alt="tsplus logo"
            />
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>{children}</div>
            {buttons}
          </Fade>
        </div>
      </div>
    </div>
  )
}

Section2.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
}

export default Section2
